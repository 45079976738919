var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-form mx-auto width-narrow mb-5"},[_c('div',{staticClass:"card mx-auto mb-5 relative"},[_c('div',{staticClass:"card-body"},[_c('h1',{class:{
          'heading-bigger text-center mb-4': true,
          'pb-5 mb-5': !_vm.loaded && !_vm.loadError
        }},[_vm._v(" Register ")]),(_vm.authError)?_c('f-alert',{staticClass:"mt-4 mb-5",attrs:{"theme":"danger","visible":""}},[(_vm.currentErrors && Object.keys(_vm.currentErrors).length)?[_c('div',[_vm._l((Object.keys(_vm.currentErrors)),function(errorKey){return _vm._l((_vm.currentErrors[errorKey]),function(errorItem,errorItemIndex){return _c('div',{key:'error-' + errorKey + '-' + errorItemIndex,staticClass:"mb-1 error-item"},[_vm._v(" "+_vm._s(errorItem)+" ")])})})],2)]:[_vm._v(" Registration error. Try again. ")]],2):_vm._e(),(_vm.allDataLoaded)?_c('validation-observer',{ref:"register-form",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"rules":"required|min:3","name":"first-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"for-required",attrs:{"for":"fist-name"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],staticClass:"full-width",attrs:{"name":"fist-name","type":"text","state":errors.length ? 'invalid' : null,"placeholder":"First Name"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,3819772971)}),_c('validation-provider',{attrs:{"rules":"required|min:3","name":"last-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"for-required",attrs:{"for":"last-name"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],staticClass:"full-width",attrs:{"name":"last-name","type":"text","state":errors.length ? 'invalid' : null,"placeholder":"Last Name"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,2034242763)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"for-required",attrs:{"for":"email"}},[_vm._v("E-mail Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"full-width",attrs:{"name":"email","type":"email","disabled":_vm.isFamilyMember,"state":errors.length ? 'invalid' : null,"placeholder":"E-mail Address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,1337624416)}),_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"full-width",attrs:{"name":"phone","type":"tel","state":errors.length ? 'invalid' : null,"placeholder":"Phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,817626132)}),_c('validation-provider',{attrs:{"name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"country"}},[_vm._v("Country")]),_c('div',{class:{
                'form-select': true,
                'is-invalid': errors.length
              }},[_c('v-select',{staticClass:"mb-0",attrs:{"options":_vm.allCountries,"reduce":function (item) { return item.id; },"searchable":true,"autocomplete":'dummy',"placeholder":"Country","label":"name"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,3030150279)}),_c('validation-provider',{attrs:{"rules":"required|min:8|max:50","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"for-required",attrs:{"for":"password"}},[_vm._v(" Password: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"full-width",attrs:{"type":"password","state":errors.length ? 'invalid' : null,"placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,2548256285)}),_c('validation-provider',{attrs:{"rules":"required|min:8|max:50|password:password","name":"password-confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"for-required",attrs:{"for":"password-confirmation"}},[_vm._v(" Confirm Password: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"full-width",attrs:{"type":"password","placeholder":"Password Confirmation","state":errors.length ? 'invalid' : null},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,false,2428879586)}),_c('validation-provider',{attrs:{"rules":"required|is-checked","name":"policy-agreement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('f-checkbox',{staticClass:"block",model:{value:(_vm.policy_agreement),callback:function ($$v) {_vm.policy_agreement=$$v},expression:"policy_agreement"}},[_vm._v(" Policy agreement * ")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1907846678)}),_c('validation-provider',{attrs:{"name":"newsletter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('f-checkbox',{staticClass:"block",model:{value:(_vm.newsletter),callback:function ($$v) {_vm.newsletter=$$v},expression:"newsletter"}},[_vm._v(" Newsletter ")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,517002531)}),_c('f-button',{staticClass:"block mx-auto mt-4 text-uppercase",attrs:{"type":"submit","theme":"secondary","big":"","disabled":_vm.submitting},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(!_vm.submitting)?[_vm._v("Register")]:[_vm._v("Registering…")]],2),_c('div',{staticClass:"text-center mt-4"},[_c('f-button',{staticClass:"lnk lnk-big lnk-big-blue",attrs:{"theme":"text","disabled":_vm.submitting},nativeOn:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" Back to login page ")])],1)],1):_vm._e(),(!_vm.loaded && !_vm.loadError)?_c('div',{staticClass:"loading-spinner-wrapper mt-5"},[_vm._m(0)]):_vm._e(),(_vm.loadError)?_c('f-alert',{staticClass:"my-5",attrs:{"visible":"","theme":"warning"}},[_c('span',[_vm._v(" Loading data failed. "),_c('a',{staticClass:"lnk lnk-alert",attrs:{"href":"javascript:window.location.reload();"}},[_vm._v(" Reload page ")]),_vm._v(" to try again. ")])]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-spinner pt-5"},[_c('span',{staticClass:"loading-spinner-content"},[_vm._v(" Loading data… ")])])}]

export { render, staticRenderFns }