<template>
  <div class="auth-form mx-auto width-narrow mb-5">
    <div class="card mx-auto mb-5 relative">
      <div class="card-body">
        <h1
          :class="{
            'heading-bigger text-center mb-4': true,
            'pb-5 mb-5': !loaded && !loadError
          }">
          Register
        </h1>
        <f-alert
          v-if="authError"
          theme="danger"
          visible
          class="mt-4 mb-5">
          <template v-if="currentErrors && Object.keys(currentErrors).length">
            <div>
              <template
                v-for="(errorKey) in Object.keys(currentErrors)">
                <div
                  v-for="(errorItem, errorItemIndex) in currentErrors[errorKey]"
                  :key="'error-' + errorKey + '-' + errorItemIndex"
                  class="mb-1 error-item">
                  {{ errorItem }}
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            Registration error. Try again.
          </template>
        </f-alert>

        <validation-observer
          v-if="allDataLoaded"
          ref="register-form"
          tag="form">

          <validation-provider
            rules="required|min:3"
            name="first-name"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="fist-name" class="for-required">First Name</label>
              <input
                v-model="first_name"
                name="fist-name"
                type="text"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="First Name" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|min:3"
            name="last-name"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="last-name" class="for-required">Last Name</label>
              <input
                v-model="last_name"
                name="last-name"
                type="text"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="Last Name" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|email"
            name="email"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="email" class="for-required">E-mail Address</label>
              <input
                v-model="email"
                name="email"
                type="email"
                class="full-width"
                :disabled="isFamilyMember"
                :state="errors.length ? 'invalid' : null"
                placeholder="E-mail Address" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            name="phone"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="phone">Phone</label>
              <input
                v-model="phone"
                name="phone"
                type="tel"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="Phone" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            name="country"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="country">Country</label>
              <div
                :class="{
                  'form-select': true,
                  'is-invalid': errors.length
                }">
                <v-select
                  class="mb-0"
                  :options="allCountries"
                  v-model="country"
                  :reduce="(item) => item.id"
                  :searchable="true"
                  :autocomplete="'dummy'"
                  placeholder="Country"
                  label="name">
                </v-select>
              </div>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|min:8|max:50"
            name="password"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="password" class="for-required">
                Password:
              </label>
              <input
                class="full-width"
                type="password"
                :state="errors.length ? 'invalid' : null"
                v-model="password"
                placeholder="Password" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|min:8|max:50|password:password"
            name="password-confirmation"
            v-slot="{ errors }">
            <div class="form-group">
            <label for="password-confirmation" class="for-required">
              Confirm Password:
            </label>
              <input
                class="full-width"
                type="password"
                placeholder="Password Confirmation"
                :state="errors.length ? 'invalid' : null"
                v-model="password_confirmation" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|is-checked"
            name="policy-agreement"
            v-slot="{ errors }">
            <div class="form-group">
              <f-checkbox
                class="block"
                v-model="policy_agreement">
                Policy agreement *
              </f-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            name="newsletter"
            v-slot="{ errors }">
            <div class="form-group">
              <f-checkbox
                class="block"
                v-model="newsletter">
                Newsletter
              </f-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <f-button
            type="submit"
            theme="secondary"
            big
            class="block mx-auto mt-4 text-uppercase"
            @click.prevent="validateForm"
            :disabled="submitting">
            <template v-if="!submitting">Register</template>
            <template v-else>Registering&hellip;</template>
          </f-button>

          <div class="text-center mt-4">
            <f-button
              theme="text"
              @click.native="$router.push('/login')"
              class="lnk lnk-big lnk-big-blue"
              :disabled="submitting">
              Back to login page
            </f-button>
          </div>
        </validation-observer>

        <div
          v-if="!loaded && !loadError"
          class="loading-spinner-wrapper mt-5">
          <div class="loading-spinner pt-5">
            <span class="loading-spinner-content">
              Loading data&hellip;
            </span>
          </div>
        </div>

        <f-alert
          v-if="loadError"
          visible
          class="my-5"
          theme="warning">
          <span>
            Loading data failed.
            <a
              href="javascript:window.location.reload();"
              class="lnk lnk-alert">
              Reload page
            </a>
            to try again.
          </span>
        </f-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'Register',
  computed: {
    allDataLoaded () {
      return this.loaded && this.countriesLoaded;
    },
    inviteSlug () {
      if (this.$route.params && this.$route.params.invite_slug) {
        return this.$route.params.invite_slug;
      }

      return null;
    }
  },
  data () {
    return {
      loaded: false,
      loadError: false,
      authError: false,
      submitting: false,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      phone: null,
      country: null,
      policy_agreement: false,
      become_promotor: 0,
      newsletter: false,
      ref_link: null,
      family_member_token: null,
      currentErrors: [],
      isFamilyMember: false,
      allCountries: [],
      countriesLoaded: false
    }
  },
  mounted () {
    if (this.$route.params && this.$route.params.family_member_token) {
      Vue.set(this, 'family_member_token', this.$route.params.family_member_token);
      Vue.set(this, 'isFamilyMember', true);

      this.loadEmailData();
    } else {
      this.loaded = true;
    }

    this.loadAdditionalData();
  },
  methods: {
    register () {
      this.authError = false;
      this.submitting = true;
      let formDataToSend = this.prepareFormDataToSend();

      this.$http.post('/register/user', formDataToSend).then((response) => {
        this.submitting = false;

        if (('success' in response.data) && response.data.success === 0) {
          this.authError = true;
          if (response.data.message) {
            this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            this.currentErrors = [];
            this.currentErrors.push({ error: response.data.message });
          }
          return;
        }

        if (response.data.family_member) {
          Vue.nextTick(() => {
            this.$router.push({ name: 'Register Result', params: { success: response.data.success } });
          });
        } else if (response.data.data.id) {
          Vue.nextTick(() => {
            this.$router.push({ name: 'Register Membership', params: { id: response.data.data.id, becomePromoter: this.become_promotor } });
          });
        }
      }).catch((error) => {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        console.log(error);
        this.currentErrors = error.response.data.errors;
        this.submitting = false;
        this.authError = true;
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['register-form'], this.register);
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        policy_agreement: this.policy_agreement ? 1 : 0,
      };

      if (this.phone) {
        formDataToSend.phone = this.phone;
      }

      if (this.country) {
        formDataToSend.country = this.country;
      }

      if (this.newsletter) {
        formDataToSend.newsletter = this.newsletter;
      }

      if (this.inviteSlug) {
        formDataToSend.ref_link = this.inviteSlug;
      }

      if (this.family_member_token) {
        formDataToSend.family_member_token = this.family_member_token;
      }

      return formDataToSend;
    },
    loadEmailData() {
      this.$http.post('/register/get-email', {
        token: this.family_member_token
      }).then((response) => {
        Vue.set(this, 'loaded', true);

        if (('success' in response.data) && response.data.success === 0) {
          this.loadError = true;
          if (response.data.message) {
            this.currentErrors = [];
            this.currentErrors.push({ error: response.data.message });
          }
          return;
        }

        Vue.nextTick(() => {
          Vue.set(this, 'email', response.data.email);
        });
      }).catch((error) => {
        console.log(error);
        this.currentErrors = error.response.data.errors;
        this.loadError = true;
      });
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/get-countries',
        method: 'get',
        outputKey: 'allCountries',
        loadedKey: 'countriesLoaded',
        errorKey: 'loadError',
        noPagination: false
      });
    },
  }
}
</script>

<style scoped>
.error-item:last-child {
  margin-bottom: 0!important;
}
</style>
